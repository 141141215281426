import { info } from 'loglevel';

const FEEDBACK_WRAPPER_MISSING =
  '[Feedback component] No feedback-wrapper element found. Will do nothing.';

function setFocusableState(feedbackDropdown: HTMLElement, enable: boolean) {
  const focusableElements = feedbackDropdown.querySelectorAll(
    'a[href], button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])'
  );
  focusableElements.forEach((element: Element) => {
    const htmlElement = element as HTMLElement;
    if (enable) {
      htmlElement.removeAttribute('tabindex');
    } else {
      htmlElement.setAttribute('tabindex', '-1');
    }
  });
}
function toggleFeedbackDropdown() {
  const feedbackDropdown = document.querySelector(
    '.feedback-wrapper'
  ) as HTMLElement;
  if (!feedbackDropdown) {
    info(FEEDBACK_WRAPPER_MISSING);
    return;
  }

  const dropdownMenu = feedbackDropdown.querySelector('[role="menu"]');
  if (!dropdownMenu) {
    alert(
      '[Feedback component] No element with role="menu" not found within ".feedback-wrapper". Ensure that the element exists and the role attribute is correctly set.'
    );
    return;
  }

  const wasOpen = feedbackDropdown.getAttribute('data-open') === 'true';
  feedbackDropdown.setAttribute('data-open', String(!wasOpen));
  dropdownMenu.setAttribute('aria-hidden', wasOpen ? 'false' : 'true');

  if (wasOpen) {
    dropdownMenu.classList.add('_opacity-0', '_scale-95');
    dropdownMenu.classList.remove(
      '_opacity-100',
      '_scale-100',
      '_pointer-events-auto'
    );
    setFocusableState(feedbackDropdown, false);
  } else {
    dropdownMenu.classList.remove('_opacity-0', '_scale-95');
    dropdownMenu.classList.add(
      '_opacity-100',
      '_scale-100',
      '_pointer-events-auto'
    );
    setFocusableState(feedbackDropdown, true);

    // Move focus to the feedback-wrapper
    feedbackDropdown.focus();

    // Add focus trap
    trapFocus(feedbackDropdown);
  }
}

document.addEventListener('click', (event: MouseEvent) => {
  const feedbackDropdown = document.querySelector('.feedback-wrapper');
  if (!feedbackDropdown) {
    info(FEEDBACK_WRAPPER_MISSING);
    return;
  }

  if (!(event.target instanceof Element)) {
    return;
  }

  if (event.target.matches('.feedback-trigger')) {
    event.stopPropagation();
    toggleFeedbackDropdown();
  } else if (
    !feedbackDropdown.contains(event.target as Node) &&
    feedbackDropdown.getAttribute('data-open') === 'true'
  ) {
    toggleFeedbackDropdown(); // This will close the dropdown
  }
});

document.addEventListener('keydown', function (event) {
  const feedbackDropdown = document.querySelector('.feedback-wrapper');
  if (!feedbackDropdown) {
    info(FEEDBACK_WRAPPER_MISSING);
    return;
  }

  if (
    event.key === 'Escape' &&
    feedbackDropdown.getAttribute('data-open') === 'true'
  ) {
    toggleFeedbackDropdown();
  }
});

function trapFocus(element: HTMLElement) {
  const focusableElements = element.querySelectorAll(
    'a[href], button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])'
  );

  const firstFocusableElement = focusableElements[0] as HTMLElement;
  const lastFocusableElement = focusableElements[
    focusableElements.length - 1
  ] as HTMLElement;

  element.addEventListener('keydown', function (e) {
    if (e.key !== 'Tab') return;

    // If shift key is pressed for reverse navigation
    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        e.preventDefault();
        lastFocusableElement.focus();
      }
    } else {
      // Forward navigation
      if (document.activeElement === lastFocusableElement) {
        e.preventDefault();
        firstFocusableElement.focus();
      }
    }
  });
}

// @ts-ignore
window.toggleFeedbackDropdown = toggleFeedbackDropdown;
